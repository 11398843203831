var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Badge content" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBadgeContent) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(
            "Add textual content to the badge by supplying a string to the "
          )
        ]),
        _c("code", [_vm._v("badge")]),
        _c("span", [_vm._v(" prop, or use the named slot ")]),
        _c("code", [_vm._v("'badge'")]),
        _vm._v(". ")
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("b-avatar", { staticClass: "mr-1", attrs: { badge: "B" } }),
          _c("b-avatar", {
            staticClass: "mr-1",
            attrs: { badge: "7", variant: "primary", "badge-variant": "danger" }
          }),
          _c("b-avatar", {
            attrs: {
              "badge-variant": "success",
              badge: "1",
              src: require("@/assets/images/portrait/small/avatar-s-16.jpg")
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }