var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "match-height" },
    [
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-size")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-initials")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-color")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-light-color")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-icon")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-custom-content")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-badge")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-badge-content")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-badge-position")], 1),
      _c("b-col", { attrs: { ld: "6" } }, [_c("avatar-offset")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-square")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-rounded")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-group")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("avatar-group-tooltip")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("avatar-action")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }