var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Initials" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeInitials) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(
            "You can specify a short string as the content of an avatar via the "
          )
        ]),
        _c("code", [_vm._v("text")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("b-avatar", {
            attrs: { size: "sm", variant: "light-primary", text: "PI" }
          }),
          _c("b-avatar", { attrs: { variant: "light-secondary", text: "PI" } }),
          _c("b-avatar", {
            attrs: { size: "lg", variant: "light-success", text: "PI" }
          }),
          _c("b-avatar", {
            attrs: { size: "70px", variant: "light-danger", text: "PI" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }