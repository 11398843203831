var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Colors" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeColor) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Use the ")]),
        _c("code", [_vm._v("variant")]),
        _c("span", [
          _vm._v(" prop to specify one of Bootstrap theme variant colors.")
        ])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("b-avatar", { attrs: { variant: "primary", text: "PI" } }),
          _c("b-avatar", { attrs: { variant: "secondary", text: "PI" } }),
          _c("b-avatar", { attrs: { variant: "success", text: "PI" } }),
          _c("b-avatar", { attrs: { variant: "danger", text: "PI" } }),
          _c("b-avatar", { attrs: { variant: "warning", text: "PI" } }),
          _c("b-avatar", { attrs: { variant: "info", text: "PI" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }