var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Icons" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeIcon) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Easily use one of ")]),
        _c("code", [_vm._v("BootstrapVue's")]),
        _c("span", [_vm._v(" icons as the avatar content via the ")]),
        _c("code", [_vm._v("icon")]),
        _c("span", [
          _vm._v(" prop. The prop should be set to a valid icon name.")
        ])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-avatar",
            { attrs: { variant: "primary" } },
            [_c("feather-icon", { attrs: { icon: "HomeIcon" } })],
            1
          ),
          _c(
            "b-avatar",
            { attrs: { variant: "success" } },
            [_c("feather-icon", { attrs: { icon: "BellIcon" } })],
            1
          ),
          _c(
            "b-avatar",
            { attrs: { variant: "info" } },
            [_c("feather-icon", { attrs: { icon: "UserIcon" } })],
            1
          ),
          _c(
            "b-avatar",
            { attrs: { variant: "danger" } },
            [_c("feather-icon", { attrs: { icon: "MessageSquareIcon" } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }