var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Badge positioning" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBadgePosition) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(
            "By default the badge appears on the bottom right of the avatar. "
          )
        ]),
        _c("span", [_vm._v("You can use the ")]),
        _c("code", [_vm._v("badge-top")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("badge-right")]),
        _c("span", [_vm._v(" boolean props to switch the sides.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("b-avatar", {
            staticClass: "mr-1",
            attrs: { badge: "", "badge-variant": "success" }
          }),
          _c("b-avatar", {
            staticClass: "mr-1",
            attrs: { "badge-variant": "success", badge: "", "badge-left": "" }
          }),
          _c("b-avatar", {
            staticClass: "mr-1",
            attrs: { badge: "", "badge-variant": "success", "badge-top": "" }
          }),
          _c("b-avatar", {
            staticClass: "mr-1",
            attrs: {
              badge: "",
              "badge-variant": "success",
              "badge-left": "",
              "badge-top": ""
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }