var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Square" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSquare) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v(" Prefer a square avatar? simply set the ")]),
        _c("code", [_vm._v("square")]),
        _c("span", [_vm._v(" prop to ")]),
        _c("code", [_vm._v("true")]),
        _vm._v(". ")
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("b-avatar", { attrs: { variant: "light-primary", square: "" } }),
          _c("b-avatar", { attrs: { square: "", variant: "light-success" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }