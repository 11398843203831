var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Custom content" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCustomContent) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Use the ")]),
        _c("code", [_vm._v("default")]),
        _c("span", [
          _vm._v(
            "slot to render custom content in the avatar, for finer grained control of its appearance."
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("b-avatar", { attrs: { variant: "primary" } }, [
            _c("span", [_vm._v("Hi")])
          ]),
          _c("b-avatar", { attrs: { variant: "secondary" } }, [
            _c("span", [_vm._v("New")])
          ]),
          _c("b-avatar", { attrs: { variant: "success" } }, [
            _c("span", [_vm._v("Hey")])
          ]),
          _c("b-avatar", { attrs: { variant: "light-danger" } }, [
            _c("span", [_vm._v("Hi")])
          ]),
          _c("b-avatar", { attrs: { variant: "light-warning" } }, [
            _c("span", [_vm._v("New")])
          ]),
          _c("b-avatar", { attrs: { variant: "light-info" } }, [
            _c("span", [_vm._v("Hey")])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }