var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Rounded" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeRounded) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(" You can change the rounding by setting the prop")
        ]),
        _c("code", [_vm._v("rounded")]),
        _c("span", [_vm._v(".")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("b-avatar", {
            attrs: { rounded: "sm", variant: "light-primary" }
          }),
          _c("b-avatar", { attrs: { rounded: "", variant: "light-success" } }),
          _c("b-avatar", {
            attrs: { rounded: "top", variant: "light-danger" }
          }),
          _c("b-avatar", {
            attrs: { rounded: "lg", variant: "light-warning" }
          }),
          _c("b-avatar", { attrs: { rounded: "left", variant: "light-info" } }),
          _c("b-avatar", {
            attrs: { rounded: "right", variant: "light-primary" }
          }),
          _c("b-avatar", {
            attrs: { rounded: "bottom", variant: "light-dark" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }