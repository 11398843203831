var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Light Colors" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeColorLight) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("use class ")]),
        _c("code", [_vm._v('variant="light-{color-name}"')]),
        _c("span", [_vm._v(" to change background color of your avatar.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("b-avatar", { attrs: { text: "PI", variant: "light-primary" } }),
          _c("b-avatar", { attrs: { text: "PI", variant: "light-secondary" } }),
          _c("b-avatar", { attrs: { text: "PI", variant: "light-success" } }),
          _c("b-avatar", { attrs: { text: "PI", variant: "light-danger" } }),
          _c("b-avatar", { attrs: { text: "PI", variant: "light-warning" } }),
          _c("b-avatar", { attrs: { text: "PI", variant: "light-info" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }