var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Avatar Group with Tooltip" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeGroupTooltip) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Use bootstrapVue's ")]),
        _c("code", [_vm._v("VBTooltip")]),
        _c("span", [_vm._v(" directive with avatar to use tooltip. ")]),
        _c("span", [_vm._v("Add class ")]),
        _c("code", [_vm._v("pull-up")]),
        _c("span", [_vm._v(" for pull up the avatar on hover.")])
      ]),
      _c(
        "b-avatar-group",
        { attrs: { size: "32px" } },
        [
          _c("b-avatar", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true }
              }
            ],
            staticClass: "pull-up",
            attrs: {
              title: "Jenny Looper",
              src: require("@/assets/images/portrait/small/avatar-s-5.jpg")
            }
          }),
          _c("b-avatar", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true }
              }
            ],
            staticClass: "pull-up",
            attrs: {
              title: "Darcey Nooner",
              variant: "primary",
              src: require("@/assets/images/portrait/small/avatar-s-7.jpg")
            }
          }),
          _c("b-avatar", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true }
              }
            ],
            staticClass: "pull-up",
            attrs: {
              title: "Julee Rossignol",
              src: require("@/assets/images/portrait/small/avatar-s-10.jpg")
            }
          }),
          _c("b-avatar", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true }
              }
            ],
            staticClass: "pull-up",
            attrs: {
              title: "Elicia Rieske",
              variant: "danger",
              src: require("@/assets/images/portrait/small/avatar-s-8.jpg")
            }
          }),
          _c("b-avatar", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true }
              }
            ],
            staticClass: "pull-up",
            attrs: {
              title: "Vinnie Mostowy",
              src: require("@/assets/images/portrait/small/avatar-s-20.jpg")
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }